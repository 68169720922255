<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
          </div>
          <div class="card-body d-block">
            <a-button type="primary" class="btn-with-addon mt-2" @click="()=>{showModal('eventCategory')}">
              <span class="btn-addon">
                <i class="fe fe-plus-circle btn-addon-icon" />
              </span>
              Ajouter un type d'événement
            </a-button>                
          </div>
          <div class="card-body">
    <div class="d-flex flex-wrap mb-3">
      <div class="flex-grow-1">
        <div class="text-uppercase text-dark font-weight-bold mb-3">Type d'événement</div>
        <div class="d-flex flex-wrap row">
          <a-popover v-for="category in categories" :key="category._id"  :title="category.title[language[activeLan]]" trigger="hover" class="col-md-3 hover-cursor"  @click="EditCategory(category)">
            <template #content>
              <p>Cliquez pour modifier ou désactiver ce type d'événement</p>
            </template>
            <div :class="$style.event" >
              <div class="vb__utils__donut vb__utils__donut--danger" />
              {{category.title[language[activeLan]]}}
            </div>
          </a-popover>          
        </div>
      </div>     
    </div>
    <a-calendar v-model:value="value" @select="selectDay">
    <template #dateCellRender="{ current: value }">
      <ul class="events">
        <li v-if="getListData(value).length > 0">
           <a-badge
            :count="getListData(value).length"
            :number-style="{
              backgroundColor: '#ce2129',
              color: '#fff',
              boxShadow: '0 0 0 1px #ce2129 inset',
            }"
          />
        </li>
      </ul>
    </template>
    <template #monthCellRender="{ current: value }">
      <div v-if="eventsCount > 0" class="notes-month">
        <section><a-badge
            :count="getMonthData(value)"
            :number-style="{
              backgroundColor: '#ce2129',
              color: '#fff',
              boxShadow: '0 0 0 1px #ce2129 inset',
            }"
          /></section>
        <span>Evenements</span>
      </div>
    </template>
  </a-calendar>
  <a-modal
    v-model:visible="visible"
    :width="700"
    :height="1200"
    :title="$t('Liste des evenements')"
    ok-text="Ajouter évènement"
    @ok="showModal('event')"
  >
    <eventsCard :data="dayEvents" :date="value"/>
  </a-modal>
    <a-modal
    v-model:visible="editCategoryModal"
    ok-text="Modifer"
    cancel-text="Annuler"
    title="Modifer ou désactiver"
    @ok="submitEditCategory"
  >
      <a-form :model="editCategoryForm" label-align="left" layout="vertical">
          <a-form-item label="Titre Fr">
             <a-input v-model:value="editCategoryForm.title.fr" placeholder="Titre Fr" />
          </a-form-item>
          <a-form-item label="Titre Ar">
            <a-input v-model:value="editCategoryForm.title.ar" placeholder="Titre Ar" />
          </a-form-item>
          <a-form-item label="Titre Ang">
            <a-input v-model:value="editCategoryForm.title.en" placeholder="Titre Ang" />
          </a-form-item>        
      </a-form>    
      <br/>
      <div class="row">
        <div class="col-md-4 offset-md-3">
          <a-button @click="deleteCategory">désactiver</a-button>
        </div>
      </div>  
  </a-modal>   
          </div>
        </div>
      </div>
    </div>
    <a-modal 
      v-model:visible="addEventModal" 
      title="Ajouter un événement"
      :ok-button-props="{loading:loading.eventBtn}"
      width="600px"      
      @ok="addEvent"
      >
      <a-form class="container" :model="eventForm" label-align="left" layout="vertical">
        <div class="row">
          <div class="col-md-12">
            <a-tabs v-model:activeKey="activeKey"  default-active-key="1" size="small">
                <a-tab-pane key="1" tab="Titre">
                  <a-form-item>
                    <a-input v-model:value="eventForm.title.fr" placeholder="Titre français" />
                  </a-form-item>        
                </a-tab-pane>
                <a-tab-pane key="2" tab="عنوان">
                  <a-form-item>
                    <a-input v-model:value="eventForm.title.ar" placeholder="Titre Arabe" />
                  </a-form-item>        
                </a-tab-pane>
                <a-tab-pane key="3" tab="Title">
                  <a-form-item>
                    <a-input v-model:value="eventForm.title.en" placeholder="Titre Anglais" />
                  </a-form-item>        
                </a-tab-pane>      
            </a-tabs>
              <a-form-item label="Categorie">
                <a-select 
                  v-model:value="eventForm.category"
                >
                  <a-select-option
                    v-for="category of categories"
                    :key="category._id"
                    :value="category._id"
                  >
                   {{category.title[language[parseInt(activeKey)-1]]}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            <a-tabs v-model:activeKey="activeKey"  default-active-key="1" size="small">
                <a-tab-pane key="1" tab="Contenue">
              <a-form-item>
                <a-input
                 v-model:value="eventForm.content.fr"
                 >
                </a-input>
              </a-form-item>      
                </a-tab-pane>
                <a-tab-pane key="2" tab="محتوى">
              <a-form-item>
                <a-input
                 v-model:value="eventForm.content.ar"
                 >
                </a-input>
              </a-form-item>     
                </a-tab-pane>
                <a-tab-pane key="3" tab="Content (EN)">
              <a-form-item>
                <a-input
                 v-model:value="eventForm.content.en"
                 >
                </a-input>
              </a-form-item>      
                </a-tab-pane>  
            </a-tabs>   
            <a-form-item label="Date et heure de l'évènement">
              <a-date-picker
                v-model:value="eventForm.date"
                type="date"
                :show-time="{ format: 'HH:mm' }"
                format="YYYY-MM-DD HH:mm"
                placeholder="Choisis une date"
                style="width: 100%"
              />
            </a-form-item>                   
          </div>
        </div>
      </a-form>
    </a-modal>
    <a-modal 
      v-model:visible="addEventCategoryModal" 
      title="Ajouter un type d'événement" 
      :ok-button-props="{loading:loading.categoryBtn}"
      @ok="eventCategory"
    >
      <a-form :model="eventCategoryForm" label-align="left" layout="vertical">
          <a-form-item label="Titre Fr">
             <a-input v-model:value="eventCategoryForm.title.fr" placeholder="Titre Fr" />
          </a-form-item>
          <a-form-item label="Titre Ar">
            <a-input v-model:value="eventCategoryForm.title.ar" placeholder="Titre Ar" />
          </a-form-item>
          <a-form-item label="Titre Ang">
            <a-input v-model:value="eventCategoryForm.title.en" placeholder="Titre Ang" />
          </a-form-item>        
      </a-form>      
    </a-modal>
  </div>
</template>
<script>
 /* eslint-disable */ 
import { defineComponent, ref , computed, reactive, toRaw  } from 'vue'
import moment from 'moment'
import EventsCard from '@/components/EventsCard'
import ApiClient from "@/services/axios"
import { message } from 'ant-design-vue';
export default defineComponent({
  name:'Agenda',
  components: { EventsCard },
  setup() {
    let addEventModal = ref(false)
    const removeEmpty = (obj) =>{
      return Object.entries(obj)
        .filter(([_, v]) => v)
        .reduce(
          (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? removeEmpty(v) : v }),
          {}
        );
    }    
    let activeKey = ref('1')
    let loading = ref({
      eventBtn:false,
      categoryBtn:false,
    })
    let editCategoryForm = reactive({
      _id:'',
      title:{
        ar:'',
        fr:'',
        en:'',
      },
      status:'active'
    })
    let editCategoryModal = ref(false)
    const EditCategory = (category) =>{
      editCategoryModal.value = true
      editCategoryForm.title = category.title
      editCategoryForm._id = category._id
    }
    //,deleteCategory,submitEditCategory
    const deleteCategory = ()=>{
      ApiClient.patch('/agandas_cetegories/'+editCategoryForm._id,{data:{status:'inactive'}})
      .then(()=>{
        categories.value = categories.value.filter((el)=>el._id!=editCategoryForm._id)
        message.success("La modification a effectuée avec succès")
      })
      .catch(()=>message.error('La modification ne peut pas être effectuée'))
      .finally(()=>editCategoryModal.value = false)
    }
    const submitEditCategory = ()=>{
      ApiClient.patch('/agandas_cetegories/'+editCategoryForm._id,{data:{title:editCategoryForm.title}})
      .then(()=>{
        categories.value = [...categories.value].map((el)=>el._id==editCategoryForm._id?{...el,title:editCategoryForm.title}:el)
        message.success("La modification a effectuée avec succès")
      })
      .catch(()=>message.error('La modification ne peut pas être effectuée'))
      .finally(()=>editCategoryModal.value = false)
    }    
    let addEventCategoryModal = ref(false)
    let events = ref([])
    let categories = ref([])
    // API call to get the categories
    ApiClient.post('/agandas_cetegories/filter',{query:{status:'active'}})
    .then((res)=>categories.value = res.data)
    // API call to get the  events
    ApiClient.post('/agandas/filter',{query:{}})
      .then((res)=>events.value = res.data)
    let eventForm = reactive({
      title:{
        ar:'',
        fr:'',
        en:'',
      },
      content:{
        ar:'',
        fr:'',
        en:'',
      },
      date:undefined,
      category:''
    })
    let eventCategoryForm = reactive({
      title:{
        ar:'',
        fr:'',
        en:'',
      },
    })
    const showModal = (target) => {
      if (target == 'event'){
        eventForm.date = value.value
        addEventModal.value = true
      }
      else addEventCategoryModal.value = true
    }

    const addEvent = () => {
      loading.value.eventBtn = true
      let data = removeEmpty(toRaw(eventForm))
      data.date = moment(eventForm.date).toDate()
      //format('YYYY-MM-DD[T]hh:mm:ss.[000Z]')
      ApiClient.put('/agandas',{data})
      .then((res)=>{
        events.value.push(res.data)
        message.success("L'événement a été ajouté")
      })
      .catch(()=>message.error("Impossible d'ajouter l'événement"))
      .finally(()=>{
        loading.value.eventBtn = false
        addEventModal.value = false
        visible.value = false;
      })     
      loading.value.eventBtn = false
    }
    const eventCategory = () => {
      loading.value.categoryBtn = true
      const data = toRaw(eventCategoryForm)
      ApiClient.put('/agandas_cetegories',{data})
      .then((res)=>{
        categories.value.push(res.data)
        message.success("Le type a été ajouté")
      })
      .catch(()=>message.error("Impossible d'ajouter le type"))
      .finally(()=>{
        loading.value.categoryBtn = false
        addEventCategoryModal.value = false
      })
    }    
    const value = ref();
    const visible = ref(false)
    const dayEvents = ref([])
    const eventsCount = computed((date_value) => {
       let listData = events.value.filter(el => {
          return moment(el.date).format("MM-DD-YYYY") == moment(date_value).format("MM-DD-YYYY")
      })
      return listData;      
    })
    const handleOk = ()=> {visible.value = false;}
    const selectDay = (date_value) => {
      visible.value = true;
      dayEvents.value = events.value.filter(el => {
          return moment(el.date).format("MM-DD-YYYY") == moment(date_value).format("MM-DD-YYYY")
      })
    }
    const getListData =  (date_value) => {    
         let listData = events.value.filter(el => {
          return moment(el.date).format("MM-DD-YYYY") == moment(date_value).format("MM-DD-YYYY")
      })
      return listData;
    }
    const  getMonthData = (date_value) =>{
      console.debug(events.value)
      console.debug("*****")
      let listData = events.value.filter(el => {
          return moment(el.date).format("MM-YYYY") == moment(date_value).format("MM-YYYY")
      })
      return listData.length;
    }

    return {
      value,
      visible,
      dayEvents,
      handleOk,
      selectDay,
      getListData,
      getMonthData,
      eventsCount,
      activeKey,
      activeLan:ref(0),
      language:ref(['fr','ar','en']),
      addEventModal,
      loading,
      addEventCategoryModal,
      events,
      categories,
      eventForm,
      eventCategoryForm,
      showModal,
      addEvent,
      eventCategory,
      editCategoryForm,
      EditCategory,
      deleteCategory,
      submitEditCategory,
      editCategoryModal
    };
  },
})
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>

<style scoped>
.hover-cursor:hover {
    cursor: pointer;
    cursor: hand;
}
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
</style>