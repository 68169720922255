<template>
  <div class="card timeline">
    <div class="card-title titre">
      {{ getMomentFormat(date, 'DD-MM-YYYY') }}
    </div>
    <div class="card-body">
      <div v-if="data.length > 0" class="container py-2">
        <!-- timeline item 2 -->
        <div v-for="event in data" :key="event._id" class="row">
          <div class="col-auto text-center flex-column d-none d-sm-flex">
            <div class="row h-50">
              <div class="col border-right">&nbsp;</div>
              <div class="col">&nbsp;</div>
            </div>
            <h5 class="m-2">
              <span class="badge badge-pill bg-primary border">&nbsp;</span>
            </h5>
            <div class="row h-50">
              <div class="col border-right">&nbsp;</div>
              <div class="col">&nbsp;</div>
            </div>
          </div>
          <div class="col py-4 px-2">
            <div class="card shadow">
              <div class="card-body">
                <div class="float-right">
                  {{ getMomentFormat(event.date, 'HH:mm') }}

                  <span
                    :class="[
                      event.status === 'inactive'
                        ? 'font-size-12 badge badge-primary'
                        : 'font-size-12 badge badge-success',
                    ]"
                    >{{ event.status === 'inactive' ? 'Inactive' : 'Active' }}</span
                  >
                </div>
                <h4 class="card-title">{{ event.title.fr }}</h4>
                <p class="card-text">{{ event.content.fr }}</p>
                <div class="float-right mb-1">
                  <a-button type="primary" shape="round" @click="suppEvent(event)">
                    <template #icon>
                      <DeleteOutlined />
                      {{ event.status == 'active' ? 'Désactiver' : 'Activer' }}
                    </template>
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
      <div v-else>
        <a-empty
          :image="Empty.PRESENTED_IMAGE_SIMPLE"
          :image-style="{
            height: '60px',
          }"
        >
          <template #description>
            <span> Pas d'evenement </span>
          </template>
        </a-empty>
      </div>
      <!--container-->
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { Empty } from 'ant-design-vue'
import { DeleteOutlined } from '@ant-design/icons-vue'
import ApiClient from '@/services/axios'
import { message } from 'ant-design-vue'

export default {
  components: { DeleteOutlined },
  props: {
    data: { type: Object, default: null },
    date: { type: Object, default: null },
  },
  data: () => ({
    Empty,
  }),
  methods: {
    moment,
    getMomentFormat(value, format) {
      if (value) return moment(value).format(format)
      return moment().format(format)
    },
    suppEvent(record) {
      record.status = record.status == 'active' ? 'inactive' : 'active'
      ApiClient.patch('/agandas/' + record._id, {
        data: { status: record.status },
      })
        .then(() => {
          message.success(`L'événement est ${record.status == 'inactive' ? 'désactivé' : 'activé'}`)
        })
        .catch((e) => {
          message.warning("Impossible d'activer/désactiver le contenue")
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.container {
  height: 300px;
  overflow-x: auto;
}

.titre {
  color: $secondary;
  font-size: 2rem;
  padding-left: 3rem;
  margin-bottom: 0rem;
  color: $white;
  background-color: $primary;
}

.timeline {
  border: 1px solid $primary !important;
}

.card-body {
  padding: 0rem 0.66rem;
}
</style>