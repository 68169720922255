<template>
  <div>
    <partials-calendar  :categories="categories"  :event="events"/>  
  </div>
</template>
<script>
import PartialsCalendar from '@/components/widgets/AppPartials/Calendar'

export default {
  name: 'VbAgenda',
  components: {
    PartialsCalendar,
  },
}
</script>
